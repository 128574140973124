*:focus {
  outline: none;
  filter: brightness(2);
}

body {
  padding-bottom: 20px;
}

main {
  width: 100%;
  margin: 0 auto 22rem;
  overflow: hidden;
  background: var(--c-background-200);
  transition: background-color 1000ms ease;

  & > section {
    margin-top: 24rem;

    &:last-of-type {
      margin-bottom: 22rem;
      padding-bottom: 24rem;
    }
  }

  @media (min-width: 1024px) {
    margin: 8rem auto 22rem;
  }
}
