.slider-container {
  position: relative;
  margin-left: auto;

  .swiper {
    .swiper-wrapper {
      padding: 6rem 0;

      .swiper-slide {
        height: 36rem;
        opacity: 0.1;
        position: relative;
        transition: border 1000ms ease-in-out, opacity 1000ms ease-in-out, filter 1000ms ease-in-out,
          box-shadow 1000ms ease-in-out;

        figure {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
          border-radius: 2.4rem;

          figcaption {
            position: absolute;
            bottom: 0;
            left: 100%;
            right: 0;
            top: 0;
            padding: 2.4rem;
            background: var(--c-candy-red);

            font-size: 1.6rem;
            color: var(--c-polar-white);
            line-height: 2.4rem;
            text-align: center;
            letter-spacing: 1.4px;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            opacity: 0;

            transition: opacity 300ms ease-in-out 300ms, left 300ms ease-in-out 300ms;

            &.show {
              opacity: 1;
              left: 0;
            }

            span {
              margin-bottom: 2rem;
            }

            .link {
              margin-top: 2rem;
              cursor: pointer;

              &:hover,
              &:focus {
                transform: none;
              }

              @media (min-width: 768px) {
                bottom: 0;
              }
            }
          }
        }

        .show-caption-button {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 5rem;
          height: 5rem;

          border: none;
          line-height: 0;
          padding: 0;

          svg {
            width: 2.2rem;
            height: 2.2rem;
            stroke: var(--c-polar-white);

            transition: transform 200ms ease-in-out;
          }

          &.rotate svg {
            transform: rotate(45deg);
          }
        }

        @media screen and (min-width: 1040px) {
          height: 48rem;
        }
      }

      .swiper-slide-active {
        opacity: 1;

        figure {
          &.shadow {
            box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 0.5), 5px 5px 15px 5px rgba(0, 0, 0, 0);
          }
        }
      }

      .swiper-slide-next img {
        filter: grayscale(1);
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-image: url('../../../assets/icons/svg/i-arrow-black.svg');
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 64% auto;
      background-position: center;

      top: unset;
      bottom: 1px;
      width: 5rem;
      height: 5rem;
      z-index: 11;

      &::after {
        display: none;
      }

      &:hover {
        filter: brightness(1.4);
      }

      &:focus {
        outline: 1px solid var(--c-candy-red);
        box-shadow: none;
      }

      &.swiper-button-disabled {
        opacity: 0.6;

        @media (prefers-color-scheme: dark) {
          background-image: url('../../../assets/icons/svg/i-arrow-red.svg');
        }
        @media (prefers-color-scheme: light) {
          background-image: url('../../../assets/icons/svg/i-arrow-white.svg');
        }
      }
    }

    .swiper-button-next {
      right: 1px;
    }
    .swiper-button-prev {
      left: unset;
      right: 6rem;
      transform: rotate(180deg);
    }

    &.testimonial {
      padding-bottom: 5.6rem;

      .swiper-wrapper {
        .swiper-slide {
          height: 42rem;
          overflow: hidden;

          background-color: var(--c-background-200);
          background-size: cover;
          background-position: center;
          border-color: var(--c-background-100);
          transition: background-color 300ms ease;

          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 33.6rem 8.4rem;
          grid-template-areas:
            'quote'
            'name';

          div {
            width: 100%;
            height: 100%;
            padding: 0 2.4rem 4.8rem 2.4rem;

            &:first-of-type {
              grid-area: quote;
              overflow: scroll;
              position: relative;
              padding-right: 2.4rem;

              p {
                line-height: 1.8;

                &:first-of-type {
                  padding-top: 3.2rem;
                }
                & + p {
                  margin-top: 2em;
                }
              }
            }

            &:last-of-type {
              grid-area: name;
              padding: 0.8rem 2.4rem;
              line-height: 1.4;

              display: flex;
              justify-content: flex-start;
              align-items: center;

              background-color: var(--c-background-200);

              p {
                font-size: 1.4rem;
              }

              &::after {
                content: '';
                position: absolute;
                bottom: 8.4rem;
                left: 0;
                width: 100%;
                height: 3.2rem;
                background: linear-gradient(rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.1) 100%);

                pointer-events: none;
              }
            }
          }

          @media (min-width: 768px) {
            p {
              font-size: 1.8rem;
              letter-spacing: 1px;
            }
          }
        }
      }

      .swiper-button-prev {
        left: unset;
        right: 5.6rem;
      }
      .swiper-button-next {
        left: unset;
        right: 1px;
      }
    }
  }

  .current-slide {
    width: 5rem;
    height: 5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.dark {
    .swiper-container {
      &.testimonial {
        .swiper-wrapper {
          .swiper-slide {
            background-image: url('/assets/wires/w-testimonials-dark-100.svg');

            div:last-of-type {
              &::after {
                height: 9.6rem;
                background: linear-gradient(rgba(0, 0, 0, 0) 20%, #00000042 100%);
              }
            }
          }
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-image: url('../../../assets/icons/svg/i-arrow-white.svg');
    }
  }
}
