:root {
  --f-title: 'Orbitron', sans-serif;
  --f-body: 'Roboto', sans-serif;
  --f-regular: 400;
  --f-light: 300;
  --f-bold: 700;
}

html {
  font-size: 10px;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

body,
input,
textarea,
select,
button {
  font: var(--f-regular) 1.6rem var(--f-body);
  line-height: 2.8rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--f-title);
  text-transform: uppercase;
  letter-spacing: 3px;
}

h1 {
  font-size: 3.3rem;
  line-height: 3.6rem;
}

h2 {
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: var(--f-regular);
}

h3 {
  font-size: 2.6rem;
  line-height: 3rem;
  font-weight: var(--f-regular);
}

.lead {
  font-size: 1.8rem;
  line-height: 2.2rem;
}

p {
  font-weight: var(--f-light);
  letter-spacing: 1px;
}

small {
  font-size: 1.3rem;
  letter-spacing: 1px;
}

caption {
  font-size: 1.4rem;
  line-height: 1.6rem;
}

button {
  font-size: 1.4rem;
  line-height: 1.4rem;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }

  h2 {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }
}
