:root {
  --c-polar-white: #f5f5f5;
  --c-iridium-white: #f0f0f0;
  --c-silver-arrow: #d7d7d7;
  // --c-cosmos-black: #1B2A2F;
  --c-neutral-grey: #3f3f3f;
  --c-cosmos-black: #141f23;
  --c-night-black: #111517;
  --c-candy-red: #d20120;
  --c-candy-red-800: #38030b;
}

html,
#root {
  &.dark {
    --c-element-050: var(--c-neutral-grey);
    --c-element-100: var(--c-polar-white);
    --c-element-200: var(--c-iridium-white);
    --c-background-100: var(--c-cosmos-black);
    --c-background-200: var(--c-night-black);
  }

  &.light {
    --c-element-050: var(--c-silver-arrow);
    --c-element-100: var(--c-cosmos-black);
    --c-element-200: var(--c-night-black);
    --c-background-100: var(--c-polar-white);
    --c-background-200: var(--c-iridium-white);
  }
}

::selection {
  background: var(--c-candy-red);
  color: var(--c-iridium-white);
}

::-moz-selection {
  background: var(--c-candy-red);
  color: var(--c-iridium-white);
}

body {
  background: var(--c-background-200);
  color: var(--c-element-100);
}
